import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import CogTitleItems from "../components/CogTitleItems";

const MetodoPiuVeloce = (props) => (
  <CogTitleItems
    className={`${props.className}`}
    bgColor="#e8f0f6"
    title="<h2 class='fs-1 fw-bold'>Chi fa da sé fa ... per 4!<h2>"
    description="Hai inviato il form completo? Sei a pochi step dal tuo traguardo, ti manca davvero pochissimo."
    ctaTitle="Scopri Come Funziona"
    simple
    url="/come-funziona/"
    style={{padding: '100px 0'}}
    id="section-come-funziona"
  >
    <div className="items mt-5">
      <div className="items__block">
        <StaticImage
          src="../assets/images/step_1.png"
          className="img-fluid mx-auto"
          alt="Logo prestiti"
          placeholder="blurred"
          layout="fixed"
          width={250}
          height={250}
        />
        <div className="pt-4 items__description">
          <div className="fw-bold mb-1">1. Pronti, partenza... click!</div>
          Fai una foto al tuo documento di identità e alla tua busta paga (o al
          tuo cedolino) assicurandoti che sia tutto leggibile.
        </div>
      </div>

      <div className="items__block">
        <StaticImage
          src="../assets/images/step_2.png"
          className="img-fluid mx-auto"
          alt="Logo prestiti"
          placeholder="blurred"
          layout="fixed"
          width={250}
          height={250}
        />
        <div className="pt-4 items__description">
          <div className="fw-bold mb-1">2. Pronti, foto ...Invia!</div>
          Inviaci le foto su WhatsApp in tutta sicurezza, saremo subito da te.
        </div>
      </div>

      <div className="items__block">
        <StaticImage
          src="../assets/images/step_3.png"
          className="img-fluid mx-auto"
          alt="Logo prestiti"
          placeholder="blurred"
          layout="fixed"
          width={250}
          height={250}
        />
        <div className="pt-4 items__description">
          <div className="fw-bold mb-1">3. Stiamo per contattarti</div>
          Un nostro professionista specializzato sta per contattarti per la tua video consulenza gratuita.
        </div>
      </div>

      <div className="items__block">
        <StaticImage
          src="../assets/images/step_4.png"
          className="img-fluid mx-auto"
          alt="Logo prestiti"
          placeholder="blurred"
          layout="fixed"
          width={250}
          height={250}
        />
        <div className="pt-4 items__description">
          <div className="fw-bold mb-1">4. Ce l'hai fatta!</div>
          Ricevi il tuo prestito sicuro, veloce e conveniente. La tua prossima
          vittoria? Realizzare quel sogno a cui pensi tutti i giorni.
        </div>
      </div>
    </div>
  </CogTitleItems>
);

export default MetodoPiuVeloce;
