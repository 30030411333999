import React from "react";
import Layout from "../layouts/Layout";
import styled from "styled-components";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby'

const StyledStaticPage = styled.div`
  .static-page {
    &__title {
      text-align: center;
    }

    &__slug {
      @media(min-width:992px) {
        max-width: 50%;
      }
      a {
        text-decoration: none;
        color: #000;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    &__header {
      background-color: #fff;

      @media (min-width: 992px) {
        background: -webkit-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -o-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -ms-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: -moz-linear-gradient(
          273deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        background: linear-gradient(
          177deg,
          rgba(242, 242, 242, 0.7) 0%,
          rgba(206, 212, 216, 0.7) 65%,
          rgba(206, 212, 216, 0.698) 100%
        );
        height: 300px;
      }


      &__description {
        text-align: center;

        @media (min-width: 992px) {
          text-align: left;
          margin-left: 20px;
          max-width: 45%;
        }

        @media (min-width: 992px) {
          height: 300px;
        }
      }

      .intro {
        color: #527191;
      }

      .title {
        padding-left: 3em;
      }
    }
  }

  .wrapper {
    height: 100%;
    max-width: 1440px;
    justify-content: center;

    @media (min-width: 768px) {
      justify-content: space-between;
    }
    &__image-description {
      height: 100%;
      width: 100%;
      align-content: center;
      align-items: center;
    }
  }

  .form-multi-step {
    position: absolute;
    right: 0;
    top: 50px;
    z-index: 1;
  }

  .section {
    display: flex;
    align-items: center;
    @media (max-width: 992px) {
      flex-direction: column;
    }
    &__title {
      color: #e7c700;
      font-size: 30px;
      min-width: 400px;
      align-self: flex-start;
      text-align: left;
      padding-top: 1em;
    }
  }
`;

export default function cookiePolicy({ className }) {
  return (
    <Layout 
      helmetTitle="Cosa sono e come gestire il consenso ai cookies - Prestitifaidate"
      helmetDescription="Scopri la Cookie Policy di PrestitiFaidaTe e come gestire il consenso. Cosa sono, come vengono utilizzati e come puoi disattivare o rimuovere i cookie. "
    >
      <StyledStaticPage id="page-content">
        <div className="static-page__header">
          <div className="wrapper container d-flex align-items-center position-relative">
            <div className="d-flex align-items-center flex-column-reverse flex-lg-row wrapper__image-description">
              <StaticImage
                src="../assets/images/testatina-faq.png"
                className={className}
                alt="FAQ"
                placeholder="blurred"
                layout="fixed"
                width={161}
                height={150}
                objectFit="contain"
                quality={75}
              />
              <div className="d-flex flex-column static-page__header__description justify-content-center pb-3 pb-lg-0">
                <span className="fw-bold fs-1">Cookie Policy</span>
                <span className="intro">
                </span>
              </div>
            </div>
            
          </div>
        </div>
        <div id="page-content" className="container mx-auto">
          <div className="static-page__slug mb-5 mt-4 fs-5">
          {" "}
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            &gt; <Link to="/cookie-policy/">Cookie Policy</Link>
          </div>
          <h1 className="static-page__title mb-5 display-5 fw-bold">Cookie Policy</h1>
          <div className="static-page__content">
            <section className="section d-block">
                <div id="cookiefirst-policy-page"></div>
            </section>
          </div>
        </div>
      </StyledStaticPage>
      <MetodoPiuVeloce className="mt-5" />
    </Layout>
  );
}
