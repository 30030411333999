import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import DefaultButton from "./buttons/DefaultButton";

const StyledCogTitleItems = styled.div`
  background-color: ${(props) => props.bgColor};
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    color: #34495e;
    text-align: center;
    font-weight: 700;
    
    h1 {
      font-weight: 700;
    }
  }

  .description {
    color: #6d767b;
    text-align: center;
  }

  hr {
    max-width: 600px;
    width: 100%;
    height: 0;
    color: #e7c700;
    border: 1px solid #e7c700;
  }

  .items {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media (max-width: 1400px) {
      flex-wrap: wrap;
    }

    &__block {
      padding: 0 2em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 1400px) {
        margin-bottom: 3em;
        flex: 50%;
      }
    }

    &__title {
      margin: auto;
      font-weight: 500;
      font-size: 18px;
    }

    &__description {
      min-height: 200px;
    }
  }
`;

const CogTitleItems = (props) => (
  <StyledCogTitleItems className={`pt-2 pb-5 ${props.className}`} {...props}>
    {!props.simple && (
      <StaticImage
        src="../assets/images/ingranaggio-separatore.png"
        className="img-fluid mb-5"
        alt="Separatore"
        placeholder="blurred"
        layout="fixed"
        width={138}
        height={48}
      />
    )}

    <div
      className="title display-6"
      dangerouslySetInnerHTML={{ __html: props.title }}
    ></div>
    {props.description && (
      <>
        <hr />
        <p className="description mb-lg-5">{props.description}</p>
      </>
    )}
    <div className="container">{props.children}</div>
    {props.ctaTitle && (
      <DefaultButton
        title={props.ctaTitle}
        className="my-5 btn-outline-dark"
        url={props.url}
      />
    )}
  </StyledCogTitleItems>
);

export default CogTitleItems;
